import instanceSocial from '../../helpers/configSocial'
import { createAsyncThunk } from '@reduxjs/toolkit';

//done
export const listVideos = createAsyncThunk(
  'videos/listVideos',
  async (params) => {
    const response = await instanceSocial.get(`videos/`, {params:{...params}});
    return response.results;
  }
);
