import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select } from 'antd'
import { listPlatforms } from '../redux/apis/platformApiSocial'
import Loader from '../pages/pages/loader'
import { toast } from 'react-toastify'
import { clearError } from '../redux/slices/platformSocialSlice'

const PlatformsDropdown = ({ onChange, onBlur, value, multi = true, className = "", isAll = false }) => {
    const dispatch = useDispatch()
    const { platforms, isLoading, error } = useSelector(state => state.platform)
    let options = []
    useEffect(() => {
        if (!platforms) {

            dispatch(listPlatforms({
                expand: ['last_updated_by'].join(','),
                page: '*',
                ordering: '-id'
            }))
        }
    }, [])
    if (platforms) {
        options = platforms.map((item) => {
            return {
                label: item.platform_name,
                value: item.id
            }
        })
        if (isAll) {
            options = [{ label: "All Platforms", value: "*" }, ...options]
        }
    }


    useEffect(() => {
        if (!error && !isLoading) {
            return
        }
        toast.error(error, { onclose: () => { dispatch(clearError()) } })
    }, [error, isLoading])

    return (
        <div className=" mb-3">
            <Loader isLoading={isLoading} />
            <label className="" >Platforms</label>
            <br />
            <Select
                className={className}
                mode={multi ? "multiple" : ""}
                allowClear
                style={{
                    width: '100%',
                }}
                loading={isLoading}
                placeholder="Please select"
                onBlur={onBlur}
                value={value}
                onChange={onChange}
                options={options}
            />
        </div>
    )
}

export default PlatformsDropdown