import instanceSocial from '../../helpers/configSocial'
import { createAsyncThunk } from '@reduxjs/toolkit';

export const login = createAsyncThunk(
  'users/login',
  async (data) => {
    const response = await instanceSocial.post("users/login/", data);
    return response;
  }
);
export const register = createAsyncThunk(
  'users/signup',
  async (data) => {
    const response = await instanceSocial.post("users/signup/", data);
    return response;
  }
);
export const me = createAsyncThunk(
  'users/me',
  async () => {
    const response = await instanceSocial.get("users/me/");
    return response;
  }
);
export const resetPassword = createAsyncThunk(
  'users/reset-password',
  async (data) => {
    const response = await instanceSocial.post("users/reset-password/", data);
    return response;
  }
);
export const listUsers = createAsyncThunk(
  'users/listUsers',
  async (params) => {
    const response = await instanceSocial.get(`users/`,{params:{...params}});
    return response.results;
  }
);
export const createUser = createAsyncThunk(
  'users/createUser',
  async (data) => {
    const response = await instanceSocial.post("users/", data);
    return response;
  }
);
export const UpdateUser = createAsyncThunk(
  'users/UpdateUser',
  async ({ id, data }) => {
    const response = await instanceSocial.patch(`users/${id}/`, data);
    return response;
  }
);
export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (id) => {
    const response = await instanceSocial.delete(`users/${id}/`);
    return response;
  }
);
export const activateUser = createAsyncThunk(
  'users/activateUser',
  async (id) => {
    const response = await instanceSocial.patch(`users/${id}/`, { is_active: true });
    return response;
  }
);
export const listPermissions = createAsyncThunk(
  'users/listPermissions',
  async (params) => {
    const response = await instanceSocial.get(`users/permissions/`, { params: { ...params } });
    return response.results;
  }
);
export const createPermission = createAsyncThunk(
  'users/createPermission',
  async (data) => {
    const response = await instanceSocial.post(`users/permissions/`, data);
    return response;
  }
);
export const updatePermission = createAsyncThunk(
  'users/updatePermission',
  async ({data, id}) => {
    const response = await instanceSocial.patch(`users/permissions/${id}/`, data);
    return response;
  }
);
export const listGroups = createAsyncThunk(
  'users/listGroups',
  async (params) => {
    const response = await instanceSocial.get(`users/groups/`, {params:{...params}});
    return response.results;
  }
);
export const createGroup = createAsyncThunk(
  'users/createGroup',
  async (data) => {
    const response = await instanceSocial.post(`users/groups/`, data);
    return response;
  }
);
export const updateGroup = createAsyncThunk(
  'users/updateGroup',
  async ({data, id}) => {
    const response = await instanceSocial.patch(`users/groups/${id}/`, data);
    return response;
  }
);
export const listGroupPermissions = createAsyncThunk(
  'users/listGroupPermissions',
  async (params) => {
    const response = await instanceSocial.get(`users/auth-group-permissions/`, params);
    return response;
  }
);
export const assignPermissionToGroup = createAsyncThunk(
  'users/assignPermissionToGroup',
  async (data) => {
    const response = await instanceSocial.post(`users/auth-group-permissions/`, data);
    return response;
  }
);
export const deletePermissionFromGroup = createAsyncThunk(
  'users/deletePermissionFromGroup',
  async (id) => {
    const response = await instanceSocial.delete(`users/auth-group-permissions/${id}/`);
    return response;
  }
);
export const bulkDeletePermissionFromGroup = createAsyncThunk(
  'users/bulkDeletePermissionFromGroup',
  async (data) => {
    const response = await instanceSocial.delete(`users/auth-group-permissions/`, data);
    return response;
  }
);

export const listUserGroups = createAsyncThunk(
  'users/listUserGroups',
  async (params) => {
    const response = await instanceSocial.get(`users/auth-user-groups/`, params);
    return response;
  }
);
export const assignGroupToUser = createAsyncThunk(
  'users/assignGroupToUser',
  async (data) => {
    const response = await instanceSocial.post(`users/auth-user-groups/`, data);
    return response;
  }
);
export const deleteGroupFromUser = createAsyncThunk(
  'users/deleteGroupFromUser',
  async (id) => {
    const response = await instanceSocial.delete(`users/auth-user-groups/${id}/`);
    return response;
  }
);
export const bulkDeleteGroupFromUser = createAsyncThunk(
  'users/bulkDeleteGroupFromUser',
  async (data) => {
    const response = await instanceSocial.delete(`users/auth-user-groups/`,data);
    return response;
  }
);
export const listUserPermissions = createAsyncThunk(
  'users/listUserPermissions',
  async (params) => {
    const response = await instanceSocial.get(`users/auth-user-permissions/`, params);
    return response;
  }
);
export const assignPermissionToUser = createAsyncThunk(
  'users/assignPermissionToUser',
  async (data) => {
    const response = await instanceSocial.post(`users/auth-user-permissions/`, data);
    return response;
  }
);
export const deletePermissionFromUser = createAsyncThunk(
  'users/deletePermissionFromUser',
  async (id) => {
    const response = await instanceSocial.delete(`users/auth-user-permissions/${id}/`);
    return response;
  }
);
export const bulkDeletePermissionFromUser = createAsyncThunk(
  'users/bulkDeletePermissionFromUser',
  async (data) => {
    const response = await instanceSocial.delete(`users/auth-user-permissions/`,data);
    return response;
  }
);

export const listContentTypes = createAsyncThunk(
  'users/listContentTypes',
  async (params) => {
    const response = await instanceSocial.get(`users/content-types/`, params);
    return response.results;
  }
);