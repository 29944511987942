import instanceSocial from '../../helpers/configSocial'
import { createAsyncThunk } from '@reduxjs/toolkit';

//done
export const listCampaign = createAsyncThunk(
  'campaign/listCampaign',
  async (params) => {
    const response = await instanceSocial.get(`campaigns/`, {params:{...params}});
    return response.results;
  }
);

export const retrieveCampaign = createAsyncThunk(
  'campaign/retrieveCampaign',
  async ({id, params}) => {
    const response = await instanceSocial.get(`campaigns/${id}/`, {params:{...params}});
    return response;
  }
);
