import { combineReducers } from "@reduxjs/toolkit";
import authSocialSlice from "./slices/authSocialSlice";
import campaignSocialSlice from "./slices/campaignSocialSlice";
import analyticsSocialSlice from "./slices/analyticsSocialSlice";
import videoSocialSlice from "./slices/videoSocialSlice";
import analyticsGeneralSlice from "./slices/analyticsGeneralSlice";
import authGeneralSlice from "./slices/authGeneralSlice";
import campaignGeneralSlice from "./slices/campaignGeneralSlice";
import actionSlice from "./slices/actionSlice";
import platformSocialSlice from "./slices/platformSocialSlice";

export default combineReducers({
    authSocial: authSocialSlice,
    authGeneral: authGeneralSlice,
    campaignSocial: campaignSocialSlice,
    campaignGeneral: campaignGeneralSlice,
    analyticsSocial: analyticsSocialSlice,
    videoSocial: videoSocialSlice,
    analyticsGeneral: analyticsGeneralSlice,
    action:actionSlice,
    platform:platformSocialSlice

})