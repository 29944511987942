import instanceAdmin from '../../helpers/configAdmin';
import { createAsyncThunk } from '@reduxjs/toolkit';

//done
export const Ticket = createAsyncThunk(
  'action/Ticket',
  async ({id,data}) => {
    const response = await instanceAdmin.post(`customers_management/customerActions/customers/${id}/tickets/`, data);
    return response;
  }
);
export const TicketMedia = createAsyncThunk(
  'action/TicketMedia',
  async ({id,data}) => {
    const response = await instanceAdmin.post(`customers_management/customerActions/tickets/${id}/media-files/`, data);
    return response;
  }
);
export const GetTicket = createAsyncThunk(
  'action/GetTicket',
  async ({id}) => {
    const response = await instanceAdmin.get(`customers_management/customerActions/customers/${id}/tickets`);
    return response.results;
  }
);


