import instanceAdmin from '../../helpers/configAdmin'
import { createAsyncThunk } from '@reduxjs/toolkit';


export const getCountCampaignsQuery = createAsyncThunk(
    'campaign/allcampaigns',
    async ({id}) => {
        const response = await instanceAdmin.get(`campaigns_management/campaigns?page=*&fields=id,campaign_name,campaign_status&customer_id=${id}&relations=campaign_poster_photo_id,customer_campaign_services`);
        return response.results;
    }
);