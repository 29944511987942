import React, { useContext, useEffect, useState } from 'react';
import { AppSettings } from '../../config/app-settings';
import { useDispatch, useSelector } from 'react-redux';
import CampaignCard from '../../components/other/card/campaignCard';
import { Card, CardBody } from '../../components/other/card/card';
import Loader from '../pages/loader';
import { getCountCampaignsQuery } from '../../redux/apis/campaignApiGeneral';
import { getUserId } from '../../helpers/auth';
import { getCustomerInfo } from '../../redux/apis/analyticsApiGeneral';

const CampaignsAnalytics = () => {
    const dispatch = useDispatch();
    const userId = getUserId();
    const { campaignsCount, isLoading } = useSelector(state => state.campaignGeneral);
    const [search, setSearch] = useState("");
    const [active, setActive] = useState("all"); // Tracks the selected filter ('all', 'active', 'inactive')
    const [customerId, setCustomerId] = useState(null);
    const [filteredCampaigns, setFilteredCampaigns] = useState([]); // Stores filtered campaigns
    const { customerInfo } = useSelector(state => state.analyticsGeneral);

    // Fetch customer information when userId changes
    useEffect(() => {
        if (!userId) return;
        if (!customerInfo) {
            dispatch(getCustomerInfo({ id: userId }));
        }
    }, [userId, customerInfo]);

    // Store customer ID in local storage and component state when customer info is fetched
    useEffect(() => {
        localStorage.setItem("customerId", customerInfo?.id);
        setCustomerId(customerInfo?.id);
    }, [customerInfo]);

    // Fetch campaign count when campaignsCount or customerId changes
    useEffect(() => {
        if (!campaignsCount && customerId) {
            dispatch(getCountCampaignsQuery({ id: customerId }));
        }
    }, [campaignsCount, customerId, dispatch]);

    // Filter campaigns whenever campaignsCount, search, or active status changes
    useEffect(() => {
        setFilteredCampaigns(campaignsCount); // Initially set the filtered campaigns to the entire list
    }, [campaignsCount]);

    useEffect(() => {
        const filtered = campaignsCount?.filter(item => {
            const matchesSearch = item?.campaign_name?.toLowerCase().includes(search.toLowerCase());

            // Apply active state filter
            const matchesActive = active === 'all'
                || (active === 'active' && item.status === "Active")
                || (active === 'inactive' && item.status === "Inactive");

            return matchesSearch && matchesActive;
        });

        setFilteredCampaigns(filtered); // Update filtered campaigns based on the search and active filter
    }, [search, active, campaignsCount]);

    const context = useContext(AppSettings);
    useEffect(() => {
        context.setAppHeaderNone(false);
        context.setAppSidebarNone(false);
        context.setAppContentClass('');
        context.setAppThemePanal(true);

        return function cleanUp() {
            context.setAppHeaderNone(true);
            context.setAppSidebarNone(true);
            context.setAppContentClass('p-0');
            context.setAppThemePanal(false);
        };
    }, [context]);

    return (
        <div>
            <Loader isLoading={isLoading} />

            <div className='flex justify-between'>
                <div className="form-group mb-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => { setSearch(e.target.value); }}
                    />
                </div>
                <div className="mb-3 flex gap-3">
                    <button
                        className={`btn ${active === 'all' ? 'btn-outline-theme' : 'btn-outline-secondary'}`}
                        onClick={() => setActive('all')}
                    >
                        All
                    </button>
                    <button
                        className={`btn ${active === 'active' ? 'btn-outline-theme' : 'btn-outline-secondary'}`}
                        onClick={() => setActive('active')}
                    >
                        Active
                    </button>
                    <button
                        className={`btn ${active === 'inactive' ? 'btn-outline-theme' : 'btn-outline-secondary'}`}
                        onClick={() => setActive('inactive')}
                    >
                        Inactive
                    </button>
                </div>
            </div>

            <Card>
                <CardBody className="flex flex-wrap gap-8 justify-center">
                    {filteredCampaigns?.length === 0 ? (
                        <div style={{ textAlign: "center", margin: "20px", width: "100%" }}>
                            <h3>No campaigns found.</h3>
                        </div>
                    ) : (
                        <>
                            {filteredCampaigns?.map(item => (
                                <CampaignCard type={"active"} item={item} key={item?.id} />
                            ))}
                        </>
                    )}
                </CardBody>
            </Card>
        </div>
    );
};

export default CampaignsAnalytics;
