import instanceSocial from '../../helpers/configSocial'
import { createAsyncThunk } from '@reduxjs/toolkit';

//done
export const numberOfCampaigns = createAsyncThunk(
  'analytics/numberOfCampaigns',
  async (params) => {
    const response = await instanceSocial.get(`analytics/number-of-campaigns/`, params);
    return response;
  }
);

//done
export const detectedReportedRemoved = createAsyncThunk(
  'analytics/detectedReportedRemoved',
  async (params) => {
    const response = await instanceSocial.get(`analytics/detected-reported-removed/`, { params: { ...params }});
    return response;
  }
);
//done
export const perCustomerDetectedReportedRemoved = createAsyncThunk(
  'analytics/perCustomerDetectedReportedRemoved',
  async (params) => {
    const response = await instanceSocial.get(`analytics/per-customer-detected-reported-removed/`, { params: { ...params }});
    return response;
  }
);

//done
export const perPlatformMonthDetectedReportedRemoved = createAsyncThunk(
  'analytics/perPlatformMonthDetectedReportedRemoved',
  async (params) => {
    const response = await instanceSocial.get(`analytics/per-platform-month-detected-reported-removed/`,{ params: { ...params }});
    return response;
  }
);
//done
export const topCampaignsDetectedReportedRemoved = createAsyncThunk(
  'analytics/topCampaignsDetectedReportedRemoved',
  async (params) => {
    const response = await instanceSocial.get(`analytics/top-campaigns-detected-reported-removed/`, { params: { ...params }});
    return response;
  }
);
//done
export const dailyDetectedReportedRemoved = createAsyncThunk(
  'analytics/dailyDetectedReportedRemoved',
  async (params) => {
    const response = await instanceSocial.get(`analytics/daily-detected-reported-removed/`, {params : {...params}});
    return response;
  }
);

//done
export const topRemovals = createAsyncThunk(
  'analytics/topRemovals',
  async (params) => {
    const response = await instanceSocial.get(`analytics/top-removals/`, {params:{...params}});
    return response;
  }
);