import { createSlice } from '@reduxjs/toolkit'
import { getCountCampaignsQuery } from '../apis/campaignApiGeneral';


const initialState = {
  campaignsCount: null,
  isLoading:false,
  error:null,
  success:false,
}

export const campaignGeneralSlice = createSlice({
  name: 'campaignGeneral',
  initialState,
  reducers: {
    clearGeneralCampaigns:(state)=>{
      state.campaigns = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountCampaignsQuery.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCountCampaignsQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.campaignsCount = action.payload
      })
      .addCase(getCountCampaignsQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
  },
})

// Action creators are generated for each case reducer function
export const { clearGeneralCampaigns} = campaignGeneralSlice.actions

export default campaignGeneralSlice.reducer