import { createSlice } from '@reduxjs/toolkit'
import { listCampaign, retrieveCampaign } from '../apis/campaignApiSocial';


const initialState = {
  campaigns: null,
  campaign: null,
  isLoading: false,
  error: null,
  success: false
}

export const campaignSocialSlice = createSlice({
  name: 'campaignSocial',
  initialState,
  reducers: {
    clearCampaigns: (state) => {
      state.campaigns = null
    }, clearError: (state) => {
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listCampaign.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listCampaign.fulfilled, (state, action) => {
        state.isLoading = false;
        state.campaigns = action.payload
      })
      .addCase(listCampaign.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(retrieveCampaign.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(retrieveCampaign.fulfilled, (state, action) => {
        state.isLoading = false;
        state.campaign = action.payload
      })
      .addCase(retrieveCampaign.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
  },
})

// Action creators are generated for each case reducer function
export const { clearCampaigns, clearError } = campaignSocialSlice.actions

export default campaignSocialSlice.reducer