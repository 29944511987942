import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { slideToggle } from '../../../composables/slideToggle';

function Header() {
	const {first_name, last_name} = JSON.parse(localStorage.getItem("authUser"))
	const image = localStorage.getItem("image") || ""
	const navigate = useNavigate()
	const notificationData = [];
	const toggleAppSidebarDesktop = () => {
		var elm = document.querySelector('.app');
		if (elm) {
			if (!(elm.classList.contains('app-with-top-nav') && elm.classList.contains('app-without-sidebar'))) {
				elm.classList.toggle('app-sidebar-collapsed');

				setTimeout(() => {
					window.dispatchEvent(new Event('resize'));
				}, 150);
			}
		}
	}

	const toggleAppSidebarMobile = () => {
		var elm = document.querySelector('.app');
		if (elm) {
			if (!(elm.classList.contains('app-with-top-nav') && elm.classList.contains('app-without-sidebar'))) {
				elm.classList.toggle('app-sidebar-mobile-toggled');
			} else {
				slideToggle(document.querySelector('.app-top-nav'));
			}
		}
	}

	const toggleAppHeaderSearch = () => {
		var elm = document.querySelector('.app');
		elm.classList.toggle('app-header-menu-search-toggled');
	}

	const handleLogout = () => {
		localStorage.clear();
		window.location.href = "/login"
	};
	

	return (
		<div id="header" className="app-header">
			<div className="desktop-toggler">
				<button type="button" className="menu-toggler" onClick={toggleAppSidebarDesktop}>
					<span className="bar"></span>
					<span className="bar"></span>
					<span className="bar"></span>
				</button>
			</div>

			<div className="mobile-toggler">
				<button type="button" className="menu-toggler" onClick={toggleAppSidebarMobile}>
					<span className="bar"></span>
					<span className="bar"></span>
					<span className="bar"></span>
				</button>
			</div>

			<div className="brand">
				<Link to="/" className="brand-logo">
					<span className="brand-img">
						<span className="brand-img-text text-theme">R</span>
					</span>
					<span className="brand-text">RH | Social Media</span>
				</Link>
			</div>

			<div className="menu">
				
				
				<div className="menu-item dropdown dropdown-mobile-full">
					<a href="#/" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link">
						<div className="menu-img online">
							<div className="d-flex align-items-center justify-content-center w-100 h-100 bg-inverse bg-opacity-25 text-inverse text-opacity-50 rounded-circle overflow-hidden">
								<img src={image} alt="" />
							</div>
						</div>
						<div className="menu-text d-sm-block d-none w-170px">{first_name} {last_name}</div>
					</a>
					<div className="dropdown-menu dropdown-menu-end me-lg-3 fs-11px mt-1">
						<Link to="/settings" className="dropdown-item d-flex align-items-center">SETTINGS <i className="bi bi-gear ms-auto text-theme fs-16px my-n1"></i></Link>
						<div className="dropdown-divider"></div>
						<button onClick={handleLogout} className="dropdown-item d-flex align-items-center">LOGOUT <i className="bi bi-toggle-off ms-auto text-theme fs-16px my-n1"></i></button>
					</div>
				</div>
			</div>

			<form className="menu-search" method="POST" name="header_search_form">
				<div className="menu-search-container">
					<div className="menu-search-icon"><i className="bi bi-search"></i></div>
					<div className="menu-search-input">
						<input type="text" className="form-control form-control-lg" placeholder="Search menu..." />
					</div>
					<div className="menu-search-icon">
						<a href="#/" onClick={toggleAppHeaderSearch}><i className="bi bi-x-lg"></i></a>
					</div>
				</div>
			</form>
		</div>
	)
}

export default Header;
