import React, { useState, useEffect, useContext } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast as toastr } from "react-toastify";
import instanceAdmin from '../../helpers/configAdmin';
import { getUserId } from '../../helpers/auth';
import generateExcel from '../../helpers/generateExcel';
import Loader from '../pages/loader';
import { DatePicker, Input, Select } from 'antd';
import ServicesDropDown from '../../components/ServicesDropDown';
import CampaignDropDown from '../../components/CampaignDropDown';
import { AppSettings } from '../../config/app-settings';
import dayjs from 'dayjs';
import { Card, CardBody, CardHeader } from '../../components/other/card/card';


function getCurrentDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so +1
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

function GenerateReports() {
  const [reportId, setReportId] = useState();
  const [loading, setLoading] = useState(false);
  const [campaignName, setCampaignName] = useState(null);
  const [hideForm, setHideForm] = useState(false);
  const [customerInfo, setCustomerInfo] = useState()
  useEffect(() => {
    // Additional conditions can be added if needed
    if (reportId === 1) {
      setHideForm(true);
    } else {
      setHideForm(false);
    }
  }, [reportId]);

  const fetchCustomerInfo = async () => {
    const response = await instanceAdmin.get(
      '/customers_management/analytics/customer/details/?user_id=' + getUserId()
    );
    return response;
  };

  useEffect(() => {
    fetchCustomerInfo()

  }, [])


  const validation = useFormik({
    // enableReinitialize: use this flag when initial values need to be changed
    enableReinitialize: true,

    initialValues: {
      customer: localStorage.getItem("customerId"),
      service: "",
      report_name: "",
      start_date: "",
      end_date: "",
      service_id: "",
      campaign_id: "",
      last_updated_by_id: getUserId() || "",
    },
    validationSchema: Yup.object({
      customer: Yup.string().nullable("Please select a customer"),
      service: reportId === 1 ? Yup.string().required("Please Select Service.") : Yup.string().nullable(),
      report_name: Yup.string().nullable(),
      start_date: reportId === 1 ? Yup.string().required("Please Select Start Date.") : Yup.date().max(new Date(Date.now() - 86400000).toISOString().split("T")[0], "Start Date must me a date before today"),
      end_date: reportId === 1 ? Yup.string().required("Please Select End Date.") : Yup.date().max(new Date().toISOString().split("T")[0], "End Date must me a date before tomorrow")
        .min(Yup.ref('start_date'), "End Date can't be before the Start Date."),
      service_id: Yup.string().nullable(),
      campaign_id: Yup.string().nullable(),
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      let { value, name } = reportsAvailable.filter(x => x.id === reportId)[0];
      toastr.info("Generating report, Please wait");

      instanceAdmin.get("/reports_management/reports" + value, '').then((res) => {
        // check if status is 204
        if (res == "No Links" || res.length == 0) {
          toastr.info("No Links");
          return;
        }
        toastr.success("Downloading report, Please wait");
        generateExcel(res, name);
        setReportId()
      }).catch((err) => {
        toastr.error(err.message);
      }).finally(() => {
        setLoading(false);
        resetForm();
      });
    },
  });

  const customStyles = {
    option: (base) => {
      return {
        ...base,
        backgroundColor: "white",
      };
    }
  };

  let reportsAvailable = [
    {
      id: 1,
      label: "Reported & removed links - per service",
      value: `/new-reports?response_type=json&report_type=reported_removed_links_per_service&start_date=${validation.values.start_date}&end_date=${validation.values.end_date}${validation.values.service != 10000 ? "&service_id=" + validation.values.service : "&service_id=*"}&customer_id=${validation.values.customer}`,
      name: `${getCurrentDateTime()}_reported_removed_links_per_service`
    },
    {
      id: 2,
      label: "All Campaigns ",
      value: `/new-reports?response_type=json&report_type=active_campaigns_per_service&customer_id=${validation.values.customer}&start_date=null&end_date=null&status=*`,
      name: `${getCurrentDateTime()}_All_Campaigns`
    },

  ];

  const servicesReports =
    ['Google', 'Bing', 'Dailymotion', 'Ok.ru', 'Vimeo', 'Telegram', 'Ugc-source', 'Youtube', 'Facebook', 'Instagram', 'Twitter'];



  const context = useContext(AppSettings);
  useEffect(() => {
    context.setAppHeaderNone(false);
    context.setAppSidebarNone(false);
    context.setAppContentClass('');
    context.setAppThemePanal(true)

    return function cleanUp() {
      context.setAppHeaderNone(true);
      context.setAppSidebarNone(true);
      context.setAppContentClass('p-0');
      context.setAppThemePanal(false)
    };
  }, []);


  return (
    <Card >
      <Loader isLoading={loading} />
      <CardBody>

        <form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >

          <div className="mb-3">
            <Select
              onChange={(selectedOption, x) => {
                setReportId(x.id);
                return validation.setFieldValue("report_name", x.label);
              }
              }
              className={"customSelect w-full"}
              placeholder="Select Report"
              name='report_name'
              id='report_name'
              classNamePrefix="select"
              onBlur={validation.handleBlur}
              styles={customStyles}
              aria-invalid={
                validation.touched.report_name && validation.errors.report_name ? true : false
              }
              required
              options={reportsAvailable} />
            {validation.touched.report_name && validation.errors.report_name ? (
              <div className="text-danger">{validation.errors.report_name}</div>
            ) : null}
          </div>


          {[1].includes(reportId) && <>
            <div className="mb-3">
              <ServicesDropDown onChange={(selectedOption, e) => {
                return validation.setFieldValue("service", e?.value || "")
              }}
                onBlur={validation.handleBlur}
                value={validation.values.service}
                validation={validation}
                filter={servicesReports}
                isAll={true}
                exclude={["facebook", "twitter", "instagram", "youtube", "tiktok"]}
              />
            </div>
          </>}
          {[1].includes(reportId) && <>

            <div className="mb-3">
              <CampaignDropDown
                onChange={(selectedOption, e) => {
                  setCampaignName(e.label)
                  return validation.setFieldValue("campaign_id", e.value)
                }}
                onBlur={validation.handleBlur}
                value={validation.values.campaign_id}
                validation={validation}
                customer_id={validation.values.customer}
                optional={true}
              />
            </div>


          </>}
          {[1].includes(reportId) && <>
            <div>

              <div>
                <div className=" mb-3 customeDate">
                  <label className="" >Start Date</label>
                  <br />
                  <DatePicker
                    name='report_date'
                    onChange={(e, date) => validation.setFieldValue("start_date", date)}
                    onBlur={validation.handleBlur}
                    value={validation.values.start_date ? dayjs(validation.values.start_date) : ""}
                  />
                </div>
                {validation.touched.start_date && validation.errors.start_date ? (
                  <p className='text-danger'>{validation.errors.start_date}</p>
                ) : null}
              </div>
              <div>
                <div className=" mb-3 customeDate">
                  <label className="" >End Date</label>
                  <br />
                  <DatePicker
                    name='end_date'
                    onChange={(e, date) => validation.setFieldValue("end_date", date)}
                    onBlur={validation.handleBlur}
                    value={validation.values.end_date ? dayjs(validation.values.end_date) : ""}
                  />
                </div>
                {validation.touched.end_date && validation.errors.end_date ? (
                  <p className='text-danger'>{validation.errors.end_date}</p>
                ) : null}
              </div>
            </div>
          </>
          }
          <div>
            <button type="submit" className="btn btn-outline-theme" disabled={loading}>
              Generate
            </button>
          </div>
        </form>
      </CardBody>


    </Card >

  )
}

export default GenerateReports