import { createSlice } from '@reduxjs/toolkit'
import {  listPlatforms } from '../apis/platformApiSocial';


const initialState = {
  platforms: null,
  isLoading: false,
  error: null,
  success: false
}

export const platformSocialSlice = createSlice({
  name: 'platform',
  initialState,
  reducers: {
    clearPlatform: (state) => {
      state.platforms = null;
    },
    clearError: (state) => {
      state.error = null
    },
    clearSuccess: (state) => {
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listPlatforms.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listPlatforms.fulfilled, (state, action) => {
        state.isLoading = false;
        state.platforms = action.payload
      })
      .addCase(listPlatforms.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      
    //------------
  },
})

// Action creators are generated for each case reducer function
export const { clearPlatform, clearError, clearSuccess } = platformSocialSlice.actions

export default platformSocialSlice.reducer