import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppSettings } from '../../config/app-settings';
import 'lity';
import 'lity/dist/lity.min.css';
import { Card } from './../../components/other/card/card.jsx';
import { NavScrollTo } from './../../components/other/nav-scroll-to/nav-scroll-to.jsx';

import { useDispatch, useSelector } from 'react-redux';
import Loader from '../pages/loader.js';

const Profile = () => {
    const { first_name, last_name, email } = JSON.parse(localStorage.getItem("authUser"))

    const closeModalRef = useRef(null);
    const { isLoading, error,success } = useSelector(state => state.authGeneral)
    const dispatch = useDispatch()
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [err, setErr] = useState(null)

    const context = useContext(AppSettings);


    useEffect(() => {
        if (success && closeModalRef.current) {
            closeModalRef.current.click(); // Programmatically click the close button
        }
    }, [success]);


    useEffect(() => {
        context.setAppHeaderNone(false);
        context.setAppSidebarNone(false);
        context.setAppContentClass('');
        context.setAppThemePanal(true)

        return function cleanUp() {
            context.setAppHeaderNone(true);
            context.setAppSidebarNone(true);
            context.setAppContentClass('p-0');
            context.setAppThemePanal(false)
        };
    }, []);

    const handleChangePassword = () => {
        if (password.length == 0) {
            setErr("Please Enter Your Password.")
            return
        }
        if (password.length < 6) {
            setErr("Password cannot be less than six characters.")
            return
        }
        if (password !== confirmPassword) {
            setErr("Password and Confirm Password Not matching.")
            return
        }
        // dispatch(resetPassword({
        //     password: password,
        //     repeat_password: confirmPassword
        // }))
        setPassword("")
        setConfirmPassword("")
        setErr(null)
    }
    return (
        <>
            <div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-10">
                            <div className="row">
                                <div className="col-xl-9">
                                    <div id="general" className="mb-5">
                                        <h4><i className="far fa-user fa-fw text-theme"></i> General</h4>
                                        <p>View and update your general account information and settings.</p>
                                        <Card>
                                            <div className="list-group list-group-flush">
                                                <div className="list-group-item d-flex align-items-center">
                                                    <div className="flex-1 text-break">
                                                        <div>Name</div>
                                                        <div className="text-inverse text-opacity-50">{first_name} {last_name}</div>
                                                    </div>
                                                </div>

                                                <div className="list-group-item d-flex align-items-center">
                                                    <div className="flex-1 text-break">
                                                        <div>Email address</div>
                                                        <div className="text-inverse text-opacity-50">{email}</div>
                                                    </div>
                                                </div>
                                                {/* <div className="list-group-item d-flex align-items-center">
                                                    <div className="flex-1 text-break">
                                                        <div>Password</div>
                                                    </div>
                                                    <div>
                                                        <a href="#modalPassword" data-bs-toggle="modal" className="btn btn-outline-default w-100px">Change</a>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </Card>
                                    </div>




                                </div>

                                <div className="col-xl-3">
                                    <NavScrollTo>
                                        <nav className="nav">
                                            <a className="nav-link" href="#general" data-toggle="scroll-to">General</a>
                                        </nav>
                                    </NavScrollTo>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalPassword">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <Loader isLoading={isLoading} />
                            <div className="modal-header">
                                <h5 className="modal-title">Change Password</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label className="form-label">Password</label>
                                    <div className="row row-space-10">
                                        <div className="col-6">
                                            <input
                                                type='password'
                                                className="form-control"
                                                placeholder="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Confirm Password</label>
                                    <div className="row row-space-10">
                                        <div className="col-6">
                                            <input
                                                type='password'
                                                className="form-control"
                                                placeholder="Confirm Password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {err ? <div className="alert alert-danger">{err}</div> : null}
                                {error ? <div className="alert alert-danger">{error}</div> : null}

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-default" data-bs-dismiss="modal" ref={closeModalRef}>Close</button>
                                <button type="button" className="btn btn-outline-theme" onClick={handleChangePassword} disabled={isLoading}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
}

export default Profile