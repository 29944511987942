import instanceAdmin from '../../helpers/configAdmin'
import { createAsyncThunk } from '@reduxjs/toolkit';

export const login = createAsyncThunk(
  'users/login',
  async (data) => {
    const response = await instanceAdmin.post("users_management/users/login/", data);
    return response;
  }
);
export const fastLogin = createAsyncThunk(
  'users/fastLogin',
  async (data) => {
    const response = await instanceAdmin.get(`users_management/customers/profile/${data}`);
    return response;
  }
);
