import React from 'react';
import Chart from 'react-apexcharts';

function BarChart({ data }) {
  var themeColor = (getComputedStyle(document.body).getPropertyValue('--bs-theme')).trim();
  var themeFont = (getComputedStyle(document.body).getPropertyValue('--bs-body-font-family')).trim();
  var themeFontWeight = (getComputedStyle(document.body).getPropertyValue('--bs-body-font-weight')).trim();
  var gray500 = (getComputedStyle(document.body).getPropertyValue('--bs-gray-500')).trim();
  var inverse = (getComputedStyle(document.body).getPropertyValue('--bs-inverse')).trim();
  var inverseRgb = (getComputedStyle(document.body).getPropertyValue('--bs-inverse-rgb')).trim();
  var borderColor = (getComputedStyle(document.body).getPropertyValue('--bs-border-color')).trim();

  // Extract categories (domain names) and data (count values) from the props
  const categories = Object.keys(data);
  const seriesData = Object.values(data);

  var barChartOptions = {
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: { position: 'top' },
        barWidth: '20%' // This controls the thickness of the bars (you can adjust this)
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: { fontSize: '12px', colors: ['#ffffff'] },
    },
    colors: [themeColor, gray500],
    stroke: { show: false },
    grid: { borderColor: borderColor },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: true,
        color: 'rgba(' + inverseRgb + ', .25)',
        height: 1,
        width: '100%',
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: 'rgba(' + inverseRgb + ', .25)',
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: '12px',
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: 'apexcharts-xaxis-label',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: '12px',
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: 'apexcharts-xaxis-label',
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };

  var barChartData = [{ data: seriesData }];

  return (
    <div className="h-[500px]"> {/* Ensure the container takes full width */}
      <Chart
        type="bar"
        options={barChartOptions}
        series={barChartData}
        width="80%"
        height="500px"
    />
    </div>
  );
}

export default BarChart;
