import { createSlice } from '@reduxjs/toolkit'
import { listVideos } from '../apis/videosApiSocial';


const initialState = {
  videos: null,
  videosDuration: null,
  videosTypes: null,
  isLoading: false,
  error: null,
  success: false
}

export const videoSocialSlice = createSlice({
  name: 'videoSocial',
  initialState,
  reducers: {
    clearVideos: (state) => {
      state.videos = null
    }, clearError: (state) => {
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listVideos.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listVideos.fulfilled, (state, action) => {
        state.isLoading = false;
        state.videos = action.payload
      })
      .addCase(listVideos.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
  },
})

// Action creators are generated for each case reducer function
export const { clearVideos, clearError } = videoSocialSlice.actions

export default videoSocialSlice.reducer