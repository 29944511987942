import React, { useContext, useEffect, useState } from 'react';
import { AppSettings } from '../../config/app-settings';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from '../../components/other/card/card';
import Loader from '../pages/loader';
import CampaignCardSocial from '../../components/other/card/campaignCardSocial';
import { listCampaign } from '../../redux/apis/campaignApiSocial';

const CampaignsAnalyticsSocial = () => {
    const dispatch = useDispatch();
    const { campaigns, isLoading } = useSelector(state => state.campaignSocial);
    const [search, setSearch] = useState("");
    const [active, setActive] = useState("all");
    const [filteredCampaigns, setFilteredCampaigns] = useState([]);






    useEffect(() => {
        if (!campaigns) {
            dispatch(listCampaign());
        }
    }, [campaigns]);

    useEffect(() => {
        setFilteredCampaigns(campaigns);
    }, [campaigns]);

    useEffect(() => {
        const filtered = campaigns?.filter(item => {
            const matchesSearch = item?.campaign_name?.toLowerCase().includes(search.toLowerCase());

            const matchesActive = active === 'all'
                || (active === 'active' && item.campaign_status === true)
                || (active === 'inactive' && item.campaign_status === false);

            return matchesSearch && matchesActive;
        });
        setFilteredCampaigns(filtered);
    }, [search, active, campaigns]);


    const context = useContext(AppSettings);
    useEffect(() => {
        context.setAppHeaderNone(false);
        context.setAppSidebarNone(false);
        context.setAppContentClass('');
        context.setAppThemePanal(true);

        return function cleanUp() {
            context.setAppHeaderNone(true);
            context.setAppSidebarNone(true);
            context.setAppContentClass('p-0');
            context.setAppThemePanal(false);
        };
    }, [context]);

   

    return (
        <div>
            <Loader isLoading={isLoading} />

            <div className='flex justify-between'>
                <div className="form-group mb-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => { setSearch(e.target.value) }}
                    />
                </div>
                <div className="mb-3 flex gap-3">
                    <button
                        className={`btn ${active === 'all' ? 'btn-outline-theme' : 'btn-outline-secondary'}`}
                        onClick={() => setActive('all')}
                    >
                        All
                    </button>
                    <button
                        className={`btn ${active === 'active' ? 'btn-outline-theme' : 'btn-outline-secondary'}`}
                        onClick={() => setActive('active')}
                    >
                        Active
                    </button>
                    <button
                        className={`btn ${active === 'inactive' ? 'btn-outline-theme' : 'btn-outline-secondary'}`}
                        onClick={() => setActive('inactive')}
                    >
                        Inactive
                    </button>
                </div>
            </div>
            <Card>
                <CardBody className="flex flex-wrap gap-8 justify-center">
                    {filteredCampaigns?.length == 0 ?
                        <div style={{ textAlign: "center", margin: "20px", width: "100%" }}>
                            <h3>No campaigns found.</h3>
                        </div> :
                        <>


                            {filteredCampaigns?.map(item => (
                                <CampaignCardSocial type={"active"} item={item} key={item?.id} />
                            ))}
                        </>
                    }
                </CardBody>
            </Card>
        </div>
    );
};

export default CampaignsAnalyticsSocial;
