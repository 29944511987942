import { createSlice } from '@reduxjs/toolkit'
import { GetTicket, Ticket, TicketMedia } from '../apis/ActionApi'


const initialState = {
  ticket: null,
  tickets: null,
  media:null,
  isLoading:false,
  isLoadingMedia:false,
  error:null,
  errorMedia:null,
  success:false,
  successMedia:false
}

export const actionSlice = createSlice({
  name: 'action',
  initialState,
  reducers: {
    clearSuccess:(state)=>{
      state.success = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(Ticket.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.success= false
      })
      .addCase(Ticket.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ticket = action.payload
        state.success= true

      })
      .addCase(Ticket.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.success= false

      })
      .addCase(GetTicket.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(GetTicket.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tickets = action.payload

      })
      .addCase(GetTicket.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;

      })
      .addCase(TicketMedia.pending, (state) => {
        state.isLoadingMedia = true;
        state.errorMedia = null;
      })
      .addCase(TicketMedia.fulfilled, (state, action) => {
        state.isLoadingMedia = false;
        state.media = action.payload
      })
      .addCase(TicketMedia.rejected, (state, action) => {
        state.isLoadingMedia = false;
        state.errorMedia = action.error.message;
      })
      
  },
})

// Action creators are generated for each case reducer function
export const { clearSuccess} = actionSlice.actions

export default actionSlice.reducer