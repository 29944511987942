import React from 'react'
import { Card, CardBody, CardHeader } from '../../other/card/card'

const DetailsHeaderGenral = ({data, customerInfo}) => {
    return (
        <Card>
            <CardHeader className="flex gap-4 items-center">
                <img
                    src={customerInfo?.media?.name}
                    alt=""
                    className="avatar-md rounded-circle me-3"
                    style={{
                        width: "50px",
                        height: "50px",
                    }}
                />
            </CardHeader>
            <CardBody className="flex justify-between items-center">
                <div>

                    <h5>campaign details:</h5>
                    <br />
                    <div className='flex gap-2' >
                        <i class="fas fa-angle-right"></i>
                        <p className='font-bold'>campaign Name :</p>
                        <p> {data?.campaign_name}</p>
                    </div>
                    <div className='flex gap-2' >
                        <i class="fas fa-angle-right"></i>
                        <p className='font-bold'>Campaign Status :</p>
                        <p> {data?.campaign_status === true ? "Active" : "Inactive"}</p>
                    </div>
                    <div className='flex gap-2' >
                        <i class="fas fa-angle-right"></i>
                        <p className='font-bold'>Country :</p>
                        <p> {data?.campaign_country}</p>
                    </div>
                    <div className='flex gap-2' >
                        <i class="fas fa-angle-right"></i>
                        <p className='font-bold'>Sample Of Work :</p>
                        <a href={data?.campaign_sample_of_work}
                            target="_blank"
                            rel="noreferrer"
                        >Click here</a>
                    </div>
                </div>
                <div>
                    <img
                        src={data?.campaign_poster_photo}
                        alt=""
                        style={{
                            maxHeight: "525px",
                            height: "320px",
                            width: "100%",
                            objectPosition: "center"
                        }}
                    />
                </div>
            </CardBody>
        </Card>
    )
}

export default DetailsHeaderGenral