import instanceAdmin from '../../helpers/configAdmin'
import { createAsyncThunk } from '@reduxjs/toolkit';

//done
export const getNumberOfCampaigns = createAsyncThunk(
    'analytics/numberOfCampaigns',
    async (params) => {
        const response = await instanceAdmin.get(`customers_management/analytics/number-of-campaigns/`, { params: { ...params } });
        return response;
    }
);

export const getCustomerInfo = createAsyncThunk(
    'analytics/customer',
    async ({id,params}) => {
        const response = await instanceAdmin.get(`customers_management/analytics/customer/details/?user_id=${id}&relations=media`, { params: { ...params } });
        return response;
    }
)
export const getDetectedRemovedInsightsQuery = createAsyncThunk(
    'analytics/detected-removed-insights',
    async ({id, params}) => {
        const response = await instanceAdmin.get(`customers_management/analytics/customer/detected-removed/${id}`, { params: { ...params } });
        return response;
    }
)
export const getCampaignDetailsQuery = createAsyncThunk(
    'analytics/getCampaignDetailsQuery',
    async ({id,campaign_id}) => {
        const response = await instanceAdmin.get(`customers_management/analytics/customer/${id}?campaign_id=${campaign_id}`);
        return response;
    }
)
export const getAnalyticsSummary = createAsyncThunk(
    'analytics/overall-insights',
    async ({id,params}) => {
        const response = await instanceAdmin.get(`customers_management/analytics/customer/overall/${id}`, { params: { ...params } });
        return response;
    }
)
export const getPerServiceStatisticQuery = createAsyncThunk(
    'analytics/per-service-statistics',
    async ({id,params}) => {
        const response = await instanceAdmin.get(`customers_management/analytics/customer/current-year-per-service-statistics/${id}`, { params: { ...params } });
        return response;
    }
)
export const getPerCampaignStatisticQuery = createAsyncThunk(
    'analytics/per-campaign-statistics',
    async ({id,params}) => {
        const response = await instanceAdmin.get(`customers_management/analytics/customer/current-year-per-campaign-service-statistics/${id}`, { params: { ...params } });
        return response;
    }
)
export const getTopRemovalsPerServiceQuery = createAsyncThunk(
    'analytics/top-removals-per-service',
    async ({id,params}) => {
        const response = await instanceAdmin.get(`customers_management/analytics/customer/top-removal-per-service/${id}`, { params: { ...params } });
        return response;
    }
)
export const getDailyMonthlyRemovalsQuery = createAsyncThunk(
    'analytics/daily-monthly-removals',
    async ({id, params}) => {
        const response = await instanceAdmin.get(`customers_management/analytics/customer/daily-monthly-statistics/${id}`, { params: { ...params } });
        return response;
    }
)
export const getTopPiratedDomainsQuery = createAsyncThunk(
    'analytics/top-pirated-domains',
    async ({id}) => {
        const response = await instanceAdmin.get(`customers_management/analytics/customer/current-year-top-pirated-domains/${id}`);
        return response;
    }
)
export const getTopCampaignsQuery = createAsyncThunk(
    'analytics/top-campaigns',
    async ({id,params}) => {
        const response = await instanceAdmin.get(`customers_management/analytics/customer/top-campaigns/${id}`, { params: { ...params } });
        return response;
    }
)