import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppSettings } from '../../config/app-settings';
import Loader from '../pages/loader';
import DetailsHeaderGenral from '../../components/campaigns/detailsGeneral/DetailsHeaderGenral';
import { getCampaignDetailsQuery, getCustomerInfo } from '../../redux/apis/analyticsApiGeneral';
import { getUserId } from '../../helpers/auth';
import OverallDataGeneral from '../../components/campaigns/detailsGeneral/OverallDataGeneral';
import ServicesDataCampaignGeneral from '../../components/campaigns/detailsGeneral/ServicesDataCampaignGeneral';
import MonthlyDetectedRemovedCampaignGeneral from '../../components/campaigns/detailsGeneral/MonthlyDetectedRemovedCampaignGeneral';
import TopRemovalsCampaignGeneral from '../../components/campaigns/detailsGeneral/TopRemovalsCampaignGeneral';

const CampaignsGeneralDetails = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const { customerInfo, campaignDetails, isLoading } = useSelector(state => state.analyticsGeneral)
    const userId = getUserId()
    const [customerId, setCustomerId] = useState(null)
    useEffect(() => {
        if (!userId) { return }

        dispatch(getCustomerInfo({ id: userId }))

    }, [userId])

    useEffect(() => {
        localStorage.setItem("customerId", customerInfo?.id);
        setCustomerId(customerInfo?.id)
    }, [customerInfo])

    useEffect(() => {
        if (!customerId) { return }
        dispatch(getCampaignDetailsQuery({
            id: customerId,
            campaign_id: id,
        }))

    }, [customerId])

    const context = useContext(AppSettings);
    useEffect(() => {
        context.setAppHeaderNone(false);
        context.setAppSidebarNone(false);
        context.setAppContentClass('');
        context.setAppThemePanal(true);

        return function cleanUp() {
            context.setAppHeaderNone(true);
            context.setAppSidebarNone(true);
            context.setAppContentClass('p-0');
            context.setAppThemePanal(false);
        };
    }, [context]);
    return (
        <div>
            <Loader isLoading={isLoading} />
            <div className='flex flex-col gap-8'>
                <DetailsHeaderGenral data={campaignDetails} customerInfo={customerInfo} />
                <OverallDataGeneral campaign_id={id} id={customerId} />
                <ServicesDataCampaignGeneral campaign_id={id} id={customerId} />
                <div className='flex justify-between wrapContainer gap-4' style={{ flexWrap: "wrap" }}>
                    <MonthlyDetectedRemovedCampaignGeneral campaign_id={id} id={customerId} />
                    <TopRemovalsCampaignGeneral campaign_id={id} id={customerId} />
                </div>
            </div>
        </div>
    )
}

export default CampaignsGeneralDetails