import React, { useEffect, useState } from 'react';
import { useResolvedPath, useMatch, NavLink, useLocation, matchPath } from 'react-router-dom';
import getMenuItems from '../../../config/app-menu';

function NavItem({ menu, isOpen, onToggle, ...props }) {
    const path = menu?.path || '';
    const resolved = useResolvedPath(path);
    const match = useMatch({ path: resolved.pathname });
    const location = useLocation();

    const handleToggle = (e) => {
        if (menu?.children) {
            e.preventDefault();  
            onToggle(menu);      
        }
    };

    let match2 = matchPath({ path, end: false }, location.pathname);
    let icon = menu?.icon && <div className="menu-icon"><i className={menu.icon}></i></div>;
    let img = menu?.img && <div className="menu-icon-img"><img src={menu.img} alt="" /></div>;
    let caret = menu?.children && !menu?.badge && <div className="menu-caret"><b className="caret"></b></div>;
    let label = menu?.label && <span className="menu-label ms-5px">{menu.label}</span>;
    let badge = menu?.badge && <div className="menu-badge">{menu.badge}</div>;
    let highlight = menu?.highlight && <i className="fa fa-paper-plane text-theme"></i>;
    let title = menu?.title && <div className="menu-text">{menu.title} {label} {highlight}</div>;

    return (
        <div className={`menu-item${(match || match2) ? ' active' : ''}${menu?.children ? ' has-sub' : ''}`}>
            <NavLink
                className="menu-link"
                to={menu?.path}
                {...props}
                onClick={handleToggle} 
            >
                {img} {icon} {title}{caret} {badge}
            </NavLink>
            {menu?.children && isOpen && (
                <div className="menu-submenu" style={{ display: "block" }}>
                    {menu.children.map((submenu, i) => (
                        <NavItem key={i} menu={submenu} isOpen={isOpen} onToggle={onToggle} />
                    ))}
                </div>
            )}
        </div>
    );
}


function SidebarNav() {
    const [menu, setMenu] = useState([]);
    const [openSubmenu, setOpenSubmenu] = useState(null); 

    useEffect(() => {
        const haveSocial = localStorage.getItem("social_access_token");

        const updatedMenu = getMenuItems(haveSocial);
        setMenu(updatedMenu); 
    }, []);

    const handleToggle = (menuItem) => {
        if (openSubmenu === menuItem) {
            setOpenSubmenu(null); 
        } else {
            setOpenSubmenu(menuItem); 
        }
    };

    return (
        <div className="menu">
            {menu?.map((menuItem, i) => (
                <NavItem
                    key={i}
                    menu={menuItem}
                    isOpen={openSubmenu === menuItem} 
                    onToggle={handleToggle} 
                />
            ))}
        </div>
    );
}

export default SidebarNav;
