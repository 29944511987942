import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import CryptoJS from 'crypto-js';
import { useDispatch } from "react-redux";
import { fastLogin } from "../redux/apis/authApiGeneral";

const currentDate = new Date().toISOString().split('T')[0];

const decryptText = (ciphertextBase64) => {
  try {
    const ciphertext = atob(ciphertextBase64); // Decode from Base64
    const bytes = CryptoJS.AES.decrypt(ciphertext, currentDate);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  } catch (error) {
    console.error('Decryption error:', error);
    return 'Decryption failed';
  }
};
const AuthMiddleware = ({ children }) => {
  const dispatch = useDispatch()
  const location = useLocation();
  const isAuthenticated = !!localStorage.getItem("authUser");

  const searchParams = new URLSearchParams(location.search);
  const uid = searchParams.get('uid');
  const token = searchParams.get('token');

  if(uid && token){
    const uid_decrypted = decryptText(uid);
    const token_decrypted = decryptText(token);
    localStorage.setItem("accessToken",token_decrypted);
    dispatch(fastLogin(uid_decrypted))
  }  else if (!isAuthenticated && location.pathname !== "/login") {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <>{children}</>;
};

export default AuthMiddleware;
