import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { retrieveCampaign } from '../../redux/apis/campaignApiSocial'
import { AppSettings } from '../../config/app-settings';
import Loader from '../pages/loader';
import OverallData from '../../components/campaigns/details/OverallData';
import DetailsHeader from '../../components/campaigns/details/DetailsHeader';
import PlatformsInsights from '../../components/campaigns/details/PlatformsInsights';
import DailyReportedRemoved from '../../components/campaigns/details/DailyReportedRemoved';
import TopRemovalYear from '../../components/campaigns/details/TopRemovalYear';

const CampaignsSocialDetails = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const { campaign, isLoading } = useSelector(state => state.campaignSocial)
    
    useEffect(() => {

        dispatch(retrieveCampaign({
            id: id,
            params: {
                expand: 'customer,campaign_type,platforms,country',
                fields: [
                    'id',
                    'campaign_name',
                    'campaign_status',
                    'sample_of_work',
                    'campaign_poster_path',
                    'customer.customer_name',
                    'customer.customer_logo_image_thumbnail',
                    'campaign_type.type',
                    'platforms.platform_name',
                    'country.country_name',
                ].join(',')
            }
        }))

    }, [])



    const context = useContext(AppSettings);
    useEffect(() => {
        context.setAppHeaderNone(false);
        context.setAppSidebarNone(false);
        context.setAppContentClass('');
        context.setAppThemePanal(true);

        return function cleanUp() {
            context.setAppHeaderNone(true);
            context.setAppSidebarNone(true);
            context.setAppContentClass('p-0');
            context.setAppThemePanal(false);
        };
    }, [context]);
    return (
        <div>
            <Loader isLoading={isLoading} />
            <div className='flex flex-col gap-8'>
                <DetailsHeader data={campaign} />
                <OverallData campaign_id={id} />
                <PlatformsInsights campaign_id={id} />
                <div className='flex justify-between wrapContainer gap-4' style={{ flexWrap: "wrap" }}>
                    <DailyReportedRemoved campaign_id={id}/>
                    <TopRemovalYear campaign_id={id}/>
                </div>
            </div>
        </div>
    )
}

export default CampaignsSocialDetails