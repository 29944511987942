import React, { useContext, useEffect, useState } from 'react';
import { AppSettings } from '../../config/app-settings';
import { Card, CardBody, CardFooter, CardHeader } from '../../components/other/card/card'
import { Select } from 'antd';
import { useFormik } from 'formik';
import * as Yup from "yup"
import { toast } from 'react-toastify';
import generateExcel from '../../helpers/generateExcel';
import { useDispatch, useSelector } from 'react-redux';
import { listVideos } from '../../redux/apis/videosApiSocial';
import PlatformsDropdown from '../../components/PlatformsDropdown';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { clearError as clearVideoError, clearVideos } from '../../redux/slices/videoSocialSlice';
import { clearCampaigns, clearError as clearCampaignsError } from '../../redux/slices/campaignSocialSlice';
import Loader from '../pages/loader';
import { listCampaign } from '../../redux/apis/campaignApiSocial';

const ALL_CUSTOMERS = "All_Customers";
const ALL_PLATFORMS = "All_Platforms";
const ALL_CAMPAIGNS = "All_Campaigns";


function GenerateReportsSocial() {
	const dispatch = useDispatch()
	const [selected, setSelected] = useState()
	const { videos, isLoading: videosLoading, error: videoError } = useSelector(state => state.videoSocial)
	const { campaigns, isLoading: campaignsLoading, error: campaignError } = useSelector(state => state.campaignSocial);
	const [reportId, setReportId] = useState();
	const [clientName, setClientName] = useState(ALL_CUSTOMERS)
	const [platformName, setPlatformName] = useState(ALL_PLATFORMS)
	const [campaignName, setCampaignName] = useState(ALL_CAMPAIGNS)

	useEffect(() => {
		dispatch(clearVideos())
		dispatch(clearCampaigns())
	}, [])

	const validation = useFormik({
		// enableReinitialize: use this flag when initial values need to be changed
		enableReinitialize: true,

		initialValues: {
			// customer: "",
			// campaign: "",
			platform: "",
			start_date: "",
			end_date: "",
			report_name: "",
		},
		validationSchema: Yup.object({
			// customer: [1].includes(reportId) ? Yup.string().required("Please select customer") : Yup.string().nullable(),
			platform: [1].includes(reportId) ? Yup.string().required("Please select platform") : Yup.string().nullable(),
			// campaign: /* [1].includes(reportId) ? Yup.string().required("Please select campaign") : */ Yup.string().nullable(),
			start_date: [1].includes(reportId) ? Yup.date().max(new Date(Date.now() - 86400000).toISOString().split("T")[0], "Start Date must me a date before today").required() : Yup.date().nullable(),
			end_date: [1].includes(reportId) ? Yup.date().max(new Date().toISOString().split("T")[0], "End Date must me a date before tomorrow")
				.min(Yup.ref('start_date'), "End Date can't be before the Start Date.").required() : Yup.date().nullable(),
			report_name: Yup.string().required("Please select a report")
		}),
		onSubmit: async (values, { resetForm }) => {
			console.log("first", values)
			let [{ params, name, fields, id }] = reportsAvailable.filter(item => {
				return item['id'] == reportId;
			})

			if (params.customer === "*") {
				delete params.customer
			}
			if (params.platform === "*") {
				delete params.platform
			}

			if (reportId === 1) {
				dispatch(listVideos(params))
			}
			if (reportId === 2) {
				dispatch(listCampaign(params))
			}


			toast.info("Generating report, Please wait");
		},
	});

	useEffect(() => {
		if (reportId === 1) {
			if (!videos) { return }
			let videosFormatted = videos.map((item) => {
				return selected?.fields.reduce((acc, field) => {
					const value = field.key.split('.').reduce((o, i) => (o && o[i] !== undefined) ? o[i] : null, item);
					acc[field.label] = value;
					if (field.label.includes('Date') && value !== null) {
						acc[field.label] = convertTimeStampTo12Hour(value);
					}
					return acc;
				}, {});
			});
			videosFormatted = videosFormatted.map((item) => {
				const updatedItem = { ...item };
				updatedItem["Video Status (Removed/In progress)"] = item["Removal Date"] ? "Removed" : "In Progress";
				return updatedItem;
			})
			if (videosFormatted.length) {

				toast.success("Downloading report, Please wait");
				generateExcel(videosFormatted, selected.name);
			} else {
				toast.success("No Data available");
			}
			dispatch(clearVideos())
			validation.resetForm()
		}else if (reportId === 2) {
			if (!campaigns) { return }
			let campaignsFormatted = campaigns.map((item) => {
				return selected?.fields.reduce((acc, field) => {
					const value = field.key.split('.').reduce((o, i) => (o && o[i] !== undefined) ? o[i] : null, item);
					acc[field.label] = value;
					if (field.label.includes('Date') && value !== null) {
						acc[field.label] = convertTimeStampTo12Hour(value);
					}
					return acc;
				}, {});
			});
			campaignsFormatted = campaignsFormatted.map(item => {
				let updatedItem = { ...item };
				updatedItem["Campaign Status"] = item["Campaign Status"] ? "Active" : "InActive";
				return updatedItem
			})
			if (campaignsFormatted.length) {
				toast.success("Downloading report, Please wait");
				generateExcel(campaignsFormatted, selected.name);
			} else {
				toast.success("No Data available");
			}
			dispatch(clearCampaigns())
			validation.resetForm()
		}
		setReportId(null)
	}, [reportId, videos, campaigns])


	let reportsAvailable = [
		{
			id: 1,
			label: "Reported & removed links - per platform",
			params: {
				expand: ["campaign", "campaign.customer", "video_data.video_duration", "inserted_by", "video_data", "platform", "video_data.video_type"].join(","),
				fields: ["url", "keyword", "campaign.campaign_name", "campaign.customer.customer_name", "video_data.video_total_views", "video_data.video_duration.duration", "video_data.video_type.name", "video_data.comments", "review_date", "report_date", "remove_date", "inserted_by.email", "link_type", "platform.platform_name"].join(","),
				page: '*',
				reviewed: true,
				reported: true,
				report_date__gte: validation.values.start_date,
				report_date__lte: validation.values.end_date,
				campaign: validation.values.campaign,
				platform: validation.values.platform,
				customer: validation.values.customer
			},
			fields: [
				{
					label: "Campaign Name",
					key: "campaign.campaign_name"
				},
				{
					label: "Platform",
					key: "platform.platform_name"
				},
				{
					label: "URL",
					key: "url"
				},
				{
					label: "Keyword",
					key: "keyword"
				},
				{
					label: "Total Views",
					key: "video_data.video_total_views"
				},
				{
					label: "Report Date",
					key: "report_date"
				},
				{
					label: "Removal Date",
					key: "remove_date"
				},
			],
			name: `${getCurrentDateTime()}_Reported_Removed_Links_${platformName}`
		},
		{
			id: 2,
			label: "All Campaigns",
			params: {
				expand: ["customer", "campaign_type", "platforms"].join(","),
				fields: ["id",
					"campaign_name",
					"customer.customer_name",
					"campaign_status",
					"campaign_type.type",
					"sample_of_work",
					"number_of_seasons",
					"number_of_episodes",
					"campaign_loa_dropbox_url",
					"campaign_start_date",
					"campaign_end_date",
					"campaign_rights_owner",
					"ownership_territory",
					"policy",
					"last_updated_by",
					"platforms.platform_name"
				].join(","),
				page: '*',
			},
			fields: [
				{
					label: "Campaign Name",
					key: "campaign_name"
				},
				{
					label: "Campaign Status",
					key: "campaign_status"
				},
				
			],
			name: `${getCurrentDateTime()}_All_Campaigns_Status`
		},
		
		
		
	];

	useEffect(() => {
		setSelected(reportsAvailable.find(x => x.id == reportId))
	}, [clientName, platformName, campaignName, validation.values.start_date, validation.values.end_date, validation.values.report_name])

	const context = useContext(AppSettings);
	useEffect(() => {
		context.setAppHeaderNone(false);
		context.setAppSidebarNone(false);
		context.setAppContentClass('');
		context.setAppThemePanal(true)

		return function cleanUp() {
			context.setAppHeaderNone(true);
			context.setAppSidebarNone(true);
			context.setAppContentClass('p-0');
			context.setAppThemePanal(false)
		};
	}, []);

	useEffect(() => {
		if (!videoError && !videosLoading) {
			return
		}
		toast.error(videoError, { onclose: () => { dispatch(clearVideoError()) } })
	}, [videoError, videosLoading])

	useEffect(() => {
		if (!campaignError && !campaignsLoading) {
			return
		}
		toast.error(campaignError, { onclose: () => { dispatch(clearCampaignsError()) } })
	}, [campaignError, campaignsLoading])



	return (
		<Card>
			<Loader isLoading={videosLoading || campaignsLoading} />
			<CardHeader>
				Generate Reports
			</CardHeader>
			<CardBody>
				<div className=" mb-3">
					{/* <Loader isLoading={isLoading} /> */}
					<Select
						name='report_name'
						placeholder="Select Report"
						id='report_name'
						className={"customSelect"}
						showSearch
						style={{
							width: "100%"
						}}
						optionFilterProp="label"
						filterSort={(optionA, optionB) =>
							(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
						}
						value={reportId}
						onChange={(e, option) => {
							setReportId(e);
							setSelected(reportsAvailable.find(x => x.id == e))
							validation.setFieldValue("report_name", option.label)
						}}
						onBlur={validation.handleBlur}
						options={reportsAvailable?.map(x => {
							return { label: x.label, value: x.id }
						})}
					/>
				</div>
				{/* {[1, 5].includes(reportId) &&
					<div>
						<CustomersDropdown
							isAll={true}
							onChange={(e, options) => {
								validation.setFieldValue("customer", options.value)
								setClientName(options.label.replaceAll(" ", "_") || ALL_CUSTOMERS)
							}}
							onBlur={validation.handleBlur}
							value={validation.values.customer}
							className='customSelect'
						/>
						{validation?.touched?.customer && validation?.errors?.customer ? (
							<p className="text-danger">{validation?.errors?.customer}</p>
						) : null}
					</div>
				} */}
				{[1, 3, 4, 5].includes(reportId) &&
					<div>
						<PlatformsDropdown
							isAll={true}
							onBlur={validation.handleBlur}
							value={validation.values.platform}
							multi={false}
							onChange={(e, option) => {
								validation.setFieldValue("platform", option.value)
								setPlatformName(option.label || ALL_PLATFORMS)
							}}
							className='customSelect'
						/>
						{validation?.touched?.platform && validation?.errors?.platform ? (
							<p className="text-danger">{validation?.errors?.platform}</p>
						) : null}
					</div>
				}
				{[1].includes(reportId) &&
					<div>

						<div>
							<div className=" mb-3 customeDate">
								<label className="" >Start Date</label>
								<br />
								<DatePicker
									name='report_date'
									onChange={(e, date) => validation.setFieldValue("start_date", date)}
									onBlur={validation.handleBlur}
									value={validation.values.start_date ? dayjs(validation.values.start_date) : ""}
								/>
							</div>
							{validation.touched.start_date && validation.errors.start_date ? (
								<p className='text-danger'>{validation.errors.start_date}</p>
							) : null}
						</div>
						<div>
							<div className=" mb-3 customeDate">
								<label className="" >End Date</label>
								<br />
								<DatePicker
									name='end_date'
									onChange={(e, date) => validation.setFieldValue("end_date", date)}
									onBlur={validation.handleBlur}
									value={validation.values.end_date ? dayjs(validation.values.end_date) : ""}
								/>
							</div>
							{validation.touched.end_date && validation.errors.end_date ? (
								<p className='text-danger'>{validation.errors.end_date}</p>
							) : null}
						</div>
					</div>
				}
			</CardBody>
			<CardFooter>
				<button type="submit" className="btn btn-outline-theme" onClick={validation.handleSubmit}
				// disabled={isLoading}
				>
					Generate
				</button>
			</CardFooter>
		</Card>
	)
}

export default GenerateReportsSocial;

function getCurrentDateTime() {
	const now = new Date();
	const year = now.getFullYear();
	const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so +1
	const day = String(now.getDate()).padStart(2, '0');
	const hours = String(now.getHours()).padStart(2, '0');
	const minutes = String(now.getMinutes()).padStart(2, '0');
	const seconds = String(now.getSeconds()).padStart(2, '0');

	return `${hours}${minutes}${seconds}`;
}

const convertTimeStampTo12Hour = (timeStamp) => {
	const date = new Date(timeStamp);
	const year = date.getFullYear();
	const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
	const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
	const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
	const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
	const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
	return formattedDate;
};