// app-route.js
import React from 'react';
import App from './../app.jsx';
import PagesError from './../pages/pages/error.js';
import PagesLogin from '../pages/auth/login.js';
import Profile from '../pages/profile/Profile.js';
import Settings from '../pages/settings/Settings.js';
import Dashboard from '../pages/dashboard/dashboard.js';
import { Navigate } from 'react-router-dom';
import GenerateReports from '../pages/Reports/GenerateReports.js';
import CampaignsAnalytics from '../pages/campaigns/CampaignsAnalytics.js';
import SubmitRequest from '../pages/request/SubmitRequest.js';
import SocialDashboard from '../pages/dashboard/social.js';
import CampaignsAnalyticsSocial from '../pages/campaigns/CampaignsAnalyticsSocial.js';
import CampaignsGeneralDetails from '../pages/campaigns/CampaignsGeneralDetails.js';
import CampaignsSocialDetails from '../pages/campaigns/CampaignsSocialDetails.js';
import GenerateReportsSocial from '../pages/Reports/GenerateReportsSocial.js';






export const AppRoute = [
  {
    path: '*',
    element: <App />,
    children: [
      { path: '', element: <Navigate to={"/dashboard/general"} /> },
      {
        path: 'dashboard',
        children: [
          { path: 'general', element: <Dashboard /> },
          { path: 'social-media', element: <SocialDashboard/> }
        ]
      },
      {
        path: 'campaigns-analytics/*',
        children: [
          { path: 'general', element: <CampaignsAnalytics /> },
          { path: 'general/:id', element: <CampaignsGeneralDetails/> },
          { path: 'social-media', element: <CampaignsAnalyticsSocial /> },
          { path: 'social-media/:id', element: <CampaignsSocialDetails/> },
        ]
      },
      { path: 'submit-request', element: <SubmitRequest /> },
      {
        path: 'generate-reports/*',
        children: [
          {path:'general', element: <GenerateReports /> },
          {path:'social-media', element: <GenerateReportsSocial /> }        ]
      },
      { path: 'login', element: <PagesLogin /> },
      { path: 'profile', element: <Profile />, protected: true },
      { path: 'settings', element: <Settings />, protected: true },
      { path: '*', element: <PagesError /> },
    ]
  }
];


