import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardBody, CardExpandToggler } from '../../other/card/card';
import { useDispatch, useSelector } from 'react-redux';
import ComponentLoader from '../../other/ComponentLoader';
import { getDetectedRemovedInsightsQuery } from '../../../redux/apis/analyticsApiGeneral';

const PerServiceStatsGeneral = ({id}) => {
    const dispatch = useDispatch();
    const { isLoading, detectedRemovedInsightsQuery } = useSelector(state => state.analyticsGeneral);
    const [type, setType] = useState("detected");
    const [detectedSeries, setDetectedSeries] = useState([]);
    const [removedSeries, setRemovedSeries] = useState([]);
    const [dataCategories, setDataCategories] = useState([]);

    const colorMap = {
        search_engines: "#3aa757",
        dailymotion: "#365486",
        okru: "#FFA447",
        bing: "#52D3D8",
        google: "#D92929",
        telegram: "#1a1aff",
        vimeo: "#40A2D8",
        facebook: '#3081D0',
        instagram: '#FFC47E',
        twitter: '#ffffff',
        youtube: '#C70039',
        tiktok: '#3D30A2',
        cyberlocker: '#ffffff'
    };


    useEffect(() => {
        if(!id)return
        if (!detectedRemovedInsightsQuery) {
            dispatch(getDetectedRemovedInsightsQuery({ id: id }));
        }
    }, [id]);

    const getStyles = () => {
        const themeFont = getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim();
        const themeFontWeight = getComputedStyle(document.body).getPropertyValue('--bs-body-font-weight').trim();
        const gray600 = getComputedStyle(document.body).getPropertyValue('--bs-gray-600').trim();
        const pink = getComputedStyle(document.body).getPropertyValue('--bs-pink').trim();
        const inverse = getComputedStyle(document.body).getPropertyValue('--bs-inverse').trim();
        const inverseRgb = getComputedStyle(document.body).getPropertyValue('--bs-inverse-rgb').trim();
        const borderColor = getComputedStyle(document.body).getPropertyValue('--bs-border-color').trim();

        return { themeFont, themeFontWeight, gray600, pink, inverse, inverseRgb, borderColor };
    };

    const { themeFont, themeFontWeight, gray600, pink, inverse, inverseRgb, borderColor } = getStyles();

    const areaChartOptions = {
        dataLabels: { enabled: false },
        stroke: { curve: 'smooth', width: 3 },
        colors: [pink, gray600],
        grid: { borderColor },
        yaxis: {
            labels: { style: { colors: inverse, fontSize: '12px', fontFamily: themeFont, fontWeight: themeFontWeight } }
        },
        legend: { fontFamily: themeFont, labels: { colors: inverse } },
        tooltip: { x: { format: 'dd/MM/yy HH:mm' } },
        xaxis: {
            type: "date",
            categories: dataCategories,
            axisTicks: { show: true, borderType: 'solid', color: `rgba(${inverseRgb}, .25)`, height: 6 },
            axisBorder: { show: true, color: `rgba(${inverseRgb}, .25)`, height: 1 },
            labels: { style: { colors: inverse, fontSize: '12px', fontFamily: themeFont, fontWeight: themeFontWeight } },
        },
        chart: {
            toolbar: {
                show: false // Disable the toolbar
            }
        },
    };

    const convertDateToString = (date) => {
        const year = date.slice(0, 4);
        const month = date.slice(5, 7);
        const monthName = new Date(year, month - 1).toLocaleString('en-us', { month: 'long' }).slice(0, 3);
        const yearShort = year.slice(2, 4);
        return `${monthName} ${yearShort}`;
    };

    const generateData = (data) => {
        if (!data) return;

        const tmpCategories = [];
        const tmpDetectedSeries = [];
        const tmpRemovedSeries = [];

        Object.entries(data).forEach(([service, months]) => {
            if (service === 'overall') return;

            const detectedSeriesData = [];
            const removedSeriesData = [];

            Object.entries(months).forEach(([date, statistics]) => {
                if (date === 'overall') return;

                tmpCategories.push(convertDateToString(date));
                Object.entries(statistics).forEach(([type, total_links]) => {
                    if (type === 'reported') detectedSeriesData.push(total_links);
                    if (type === 'removed') removedSeriesData.push(total_links);
                });
            });

            tmpDetectedSeries.push({ name: service, data: detectedSeriesData, color: colorMap[service] });
            tmpRemovedSeries.push({ name: service, data: removedSeriesData, color: colorMap[service] });
        });

        setDataCategories(tmpCategories);
        setDetectedSeries(tmpDetectedSeries);
        setRemovedSeries(tmpRemovedSeries);
    };

    useEffect(() => {
        generateData(detectedRemovedInsightsQuery);
    }, [detectedRemovedInsightsQuery]);

    const series = type === 'detected' ? detectedSeries : removedSeries;
    return (
        <div>
            <Card className="mb-3">
                <CardBody className="relative p-0 min-h-20">
                    <div className='p-0'>
                        {isLoading ? <ComponentLoader /> : null}
                        <div className='p-4'>
                            <div className="d-flex justify-content-between mb-3">
                                <span className="fw-bold small">Platform Insights</span>
                                <CardExpandToggler />
                            </div>
                            {series.length > 0 ?
                                <>
                                    <div className="mb-3 flex gap-3">
                                        <button
                                            className={`btn ${type === 'detected' ? 'btn-outline-theme' : 'btn-outline-secondary'}`}
                                            onClick={() => setType('detected')}
                                        >
                                            Reported
                                        </button>
                                        <button
                                            className={`btn ${type === 'removed' ? 'btn-outline-theme' : 'btn-outline-secondary'}`}
                                            onClick={() => setType('removed')}
                                        >
                                            Removed
                                        </button>
                                    </div>
                                    <div className="mb-3 h-full">
                                        <Chart type="area" height='100%' options={areaChartOptions} series={series} />
                                    </div>
                                </>
                                :
                                <div className="mb-3 h-full">

                                </div>
                            }
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

export default PerServiceStatsGeneral;
