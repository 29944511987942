const getUserId = () => {
    return JSON.parse(localStorage.getItem('authUser'))?.id;
}

const can = (permission) => {
    return JSON.parse(localStorage.getItem('authUser'))?.is_superuser || JSON.parse(localStorage.getItem('authUser'))?.all_permissions.includes(permission) || JSON.parse(localStorage.getItem('authUser'))?.groups.includes('Admins');
}

const isSuperUser = () => {
    return JSON.parse(localStorage.getItem('authUser'))?.is_superuser || JSON.parse(localStorage.getItem('authUser'))?.groups.includes('Admins');
}

const getCustomerId = () => {
    let customerId = localStorage.getItem('customerId')
    return customerId
}
export { getUserId, can, isSuperUser, getCustomerId };