import instanceSocial from '../../helpers/configSocial'
import { createAsyncThunk } from '@reduxjs/toolkit';


export const listPlatforms = createAsyncThunk(
  'platform/listPlatforms',
  async (params) => {
    const response = await instanceSocial.get(`platforms/?expand=user,last_updated_by`, {params:{...params}});
    return response.results;
  }
);