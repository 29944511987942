// menuConfig.js

const getMenuItems = (haveSocial) => {
    return [
        { is_header: true, title: 'MENU' },
        {
            path: '/dashboard', icon: 'bi bi-cpu', title: 'Dashboard',
            children: [
                { path: '/dashboard/general', title: 'General Dashboard' },
                haveSocial && { path: '/dashboard/social-media', title: 'Social Media Dashboard' }
            ].filter(Boolean)
        },
        {
            path: '/campaigns-analytics', icon: 'bi bi-cpu', title: 'Campaigns',
            children: [
                { path: '/campaigns-analytics/general', title: 'General Analytics' },
                haveSocial && { path: '/campaigns-analytics/social-media', title: 'Social Media Analytics' },
            ].filter(Boolean)
        },
        { path: '/submit-request', icon: 'bi bi-cpu', title: 'Submit a request' },
        {
            path: '/generate-reports', icon: 'bi bi-cpu', title: 'Generate Reports',
            children: [
                {path:'/generate-reports/general',icon: 'bi bi-cpu', title: 'General Reports' },
                haveSocial && {path:'/generate-reports/social-media',icon: 'bi bi-cpu', title: 'Social Media Reports' },
            ].filter(Boolean)
        },
    ].filter(Boolean);
};

export default getMenuItems;
