import React, { useEffect } from 'react';
import Chart from 'react-apexcharts';

function ChartApex({ data }) {
  var themeColor = (getComputedStyle(document.body).getPropertyValue('--bs-theme')).trim();
  var themeFont = (getComputedStyle(document.body).getPropertyValue('--bs-body-font-family')).trim();
  var themeFontWeight = (getComputedStyle(document.body).getPropertyValue('--bs-body-font-weight')).trim();
  var gray500 = (getComputedStyle(document.body).getPropertyValue('--bs-gray-500')).trim();
  var inverse = (getComputedStyle(document.body).getPropertyValue('--bs-inverse')).trim();
  var inverseRgb = (getComputedStyle(document.body).getPropertyValue('--bs-inverse-rgb')).trim();
  var borderColor = (getComputedStyle(document.body).getPropertyValue('--bs-border-color')).trim();

  const [detectedSeries, setDetectedSeries] = React.useState([]);
  const [removedSeries, setRemovedSeries] = React.useState([]);
  const [dataCategories, setDataCategories] = React.useState([]);
  
  useEffect(() => {
    if (!data) return;
    let tmpCategories = [];
    let tmpDetectedSeries = [];
    let tmpRemovedSeries = [];
    Object.entries(data).forEach(([key, value]) => {
      if (key === 'overall') return;
      tmpCategories.push(convertFullDateToString(key));
      tmpDetectedSeries.push(value.reported);
      tmpRemovedSeries.push(value.removed);
    })
    setDataCategories(tmpCategories);
    setDetectedSeries(tmpDetectedSeries);
    setRemovedSeries(tmpRemovedSeries);
    
  }, [data])
  
  const series = [
    {
      name: "Reported Links",
      data: detectedSeries,
    },
    {
      name: "Removed Links",
      data: removedSeries,
    },
  ]
  const convertFullDateToString = (date) => {

    // the date is in the format of yyyy-mm-dd
    // we need to convert it to dd MonthName yy
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const day = date.slice(8, 10);
    let monthName = new Date(year, month - 1).toLocaleString('en-us', { month: 'long' });
    // shorten the month name
    monthName = monthName.slice(0, 3); // January -> Jan
    // shorten the year
    let yearShort = year.slice(2, 4); // 2021 -> 21
    return day + ' ' + monthName ;
  }
  // line chart
  var lineChartOptions = {
    chart: { shadow: { enabled: true, color: 'rgba(' + inverseRgb + ', .5)', top: 18, left: 7, blur: 10, opacity: 1 }, toolbar: { show: false } },
    title: { text: '-', align: 'center', style: { fontSize: '14px', fontWeight: 'bold', fontFamily: themeFont, color: inverse } },
    colors: [themeColor, gray500],
    dataLabels: { enabled: true, background: { enabled: true, padding: 4, borderRadius: 2, borderWidth: 0, opacity: 0.9, dropShadow: { enabled: false } } },
    stroke: { curve: 'smooth', width: 3 },
    markers: { size: 4 },
    xaxis: {
      categories: dataCategories,
      axisBorder: { show: true, color: 'rgba(' + inverseRgb + ', .25)', height: 1, width: '100%', offsetX: 0, offsetY: -1 },
      axisTicks: { show: true, borderType: 'solid', color: 'rgba(' + inverseRgb + ', .25)', height: 6, offsetX: 0, offsetY: 0 },
      labels: { style: { colors: inverse, fontSize: '12px', fontFamily: themeFont, fontWeight: themeFontWeight, cssClass: 'apexcharts-xaxis-label' } },
      type: "date",
    },
    yaxis: {
      labels: { style: { colors: inverse, fontSize: '12px', fontFamily: themeFont, fontWeight: themeFontWeight, cssClass: 'apexcharts-xaxis-label' } }
    },
    legend: { show: true, position: 'top', offsetY: -10, horizontalAlign: 'right', floating: true, fontFamily: themeFont, labels: { colors: inverse } }
  };


  return (
    <div>
      <Chart type="line" options={lineChartOptions} series={series} height={271}/>
    </div>
  )
}

export default ChartApex;