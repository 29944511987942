import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import instanceAdmin from '../helpers/configAdmin';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function ServicesDropDown({ onChange, onBlur, value, validation, isCampaign, filter = [], isAll = false, exclude = [] }) {
    const [servicesOptions, setServicesOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                setIsLoading(true);
                const response = await instanceAdmin.get('/services_management/services/?page=*&fields=id,service_name');
                let services = response.results.reduce((accumulator, item) => {
                    if (isCampaign && (item.service_name === "live-streaming" || item.service_name === "brand-protection" || item.service_name === "app-store")) {
                        return accumulator;
                    } else {
                        const newItem = {
                            label: capitalizeFirstLetter(item.service_name),
                            value: item.id
                        };
                        if (exclude.length > 0) {
                            if (!exclude.includes(item.service_name.toLowerCase())) {
                                accumulator.push(newItem);
                            }
                        } else {
                            accumulator.push(newItem);
                        }
                        return accumulator;
                    }
                }, []);

                if (filter.length > 0) {
                    services = services.filter(option => filter.includes(option.label));
                }

                if (isAll) {
                    services.unshift({
                        label: "All",
                        value: 10000
                    });
                }

                setServicesOptions(services);
            } catch (err) {
                setError(err.message || 'Failed to fetch services');
            } finally {
                setIsLoading(false);
            }
        };

        fetchServices();
    }, [isCampaign, filter, isAll, exclude]);



    return (
        <div>
            <label htmlFor="service">Service</label>
            <br />
            <Select
                onChange={onChange}
                classNamePrefix="select"
                className={"customSelect w-full"}
                onBlur={onBlur}
                value={value}
                isLoading={isLoading}
                options={servicesOptions}
            />
            {validation.touched.service && validation.errors.service ? (
                <div className="text-danger">{validation.errors.service}</div>
            ) : null}
            {error && <div className="text-danger">{error}</div>}
        </div>
    );
}

export default ServicesDropDown;
