import React, { useContext, useEffect, useState } from 'react'
import { AppSettings } from '../../config/app-settings';
import { Card, CardBody, CardFooter, CardHeader } from '../../components/other/card/card';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Image, Input } from 'antd';
import { getCustomerInfo } from '../../redux/apis/analyticsApiGeneral';
import { getUserId } from '../../helpers/auth';
import { useDispatch, useSelector } from 'react-redux';
import { Ticket, TicketMedia } from '../../redux/apis/ActionApi';
import Loader from '../pages/loader';
import { clearSuccess } from '../../redux/slices/actionSlice'
import { toast } from 'react-toastify';
import RequestsQuery from '../../components/request/RequestsQuery';

const SubmitRequest = () => {
  const context = useContext(AppSettings);
  const dispatch = useDispatch()
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState("")
  const { customerInfo } = useSelector(state => state.analyticsGeneral)
  const { ticket, isLoading, error, success } = useSelector(state => state.action)
  const userId = getUserId()
  const [customerId, setCustomerId] = useState(null)
  const [customerName, setCustomerName] = useState(null)

  useEffect(() => {
    if (!userId) { return }
    if (!customerInfo) {
      dispatch(getCustomerInfo({ id: userId }))
    }
  }, [userId])

  useEffect(() => {
    localStorage.setItem("customerId", customerInfo?.id);
    setCustomerId(customerInfo?.id)
    setCustomerName(customerInfo?.customer_name)
  }, [customerInfo])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      customer: "",
      customer_name: "",
      description: "",
      feedback: null,
      last_updated_by: null,
      status: "Open",
      subject: ""
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("Please Enter Subject"),
      description: Yup.string().required("Please Enter Description"),
    }),
    onSubmit: async (values) => {
      dispatch(Ticket({ id: customerId, data: values }))
    },
  })

  useEffect(() => {
    if (!customerId) return
    validation.setFieldValue("customer", customerId)
  }, [customerId])
  useEffect(() => {
    if (!customerName) return
    validation.setFieldValue("customer_name", customerName)
  }, [customerName])
  useEffect(() => {
    if (!userId) return
    validation.setFieldValue("last_updated_by", userId)
  }, [userId])

  useEffect(() => {
    console.log(ticket)
  }, [ticket])

  useEffect(() => {
    if (success) {
      toast.success("Action successfuly", { onClose: () => { dispatch(clearSuccess()) } })
      if (file) {
        let formData = new FormData();
        formData.append("ticket_id", ticket.id);
        formData.append("media", file, file.name);
        dispatch(TicketMedia({
          id: ticket.id,
          data: formData
        }))
      }
    }
  }, [success])

  useEffect(() => {
    if (!file) {
      return
    }
    const objectUrl = URL.createObjectURL(file)
    setPreview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [file])

  useEffect(() => {
    context.setAppHeaderNone(false);
    context.setAppSidebarNone(false);
    context.setAppContentClass('');
    context.setAppThemePanal(true);

    return () => {
      context.setAppHeaderNone(true);
      context.setAppSidebarNone(true);
      context.setAppContentClass('p-0');
      context.setAppThemePanal(false);
    };
  }, [context]);

  return (
    <div>
      <Loader isLoading={isLoading} />
      <Card>
        <CardHeader>
          Create New Ticket
        </CardHeader>
        <CardBody>
          <div className=" mb-3 customInput">
            <label className="" >Request Subject</label>
            <Input
              required
              type="subject"
              name='subject'
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.subject}
            />
            {validation.touched.subject &&
              validation.errors.subject ? (
              <p className='text-danger'>
                {validation.errors.subject}
              </p>
            ) : null}
          </div>
          <div className="mb-3">
            <label htmlFor='description'>Request Description</label>
            <br />
            <textarea
              name="description"
              value={validation.values.description}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              placeholder='Enter url'
              style={{ height: "250px", width: '100%', backgroundColor: "transparent", border: '1px solid white', padding: "8px" }}
            />
            {validation.touched.description && validation.errors.description && (
              <p className='text-danger'>{validation.errors.description}</p>
            )}
          </div>
          <div className=" mb-3 w-full customInput">
            <label className="" >Attach Files</label>
            <div className='flex gap-4'>

              <Input
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
                accept=".png, .jpg, .jpeg"
              />
              <Image
                className=''
                style={{ width: "50px" }}
                src={preview ? preview : null}
              />
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <button className='btn btn-outline-theme' onClick={validation.handleSubmit}>Submit Ticket</button>
        </CardFooter>
      </Card>
      <br/>
      <Card>
        <CardHeader>
          Ticket Data
        </CardHeader>
        <CardBody>
          <RequestsQuery id={customerId} />

        </CardBody>
      </Card>
    </div>
  )
}

export default SubmitRequest