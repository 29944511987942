import { createSlice } from '@reduxjs/toolkit'
import { fastLogin, login } from '../apis/authApiGeneral';


const initialState = {
  user: {},
  isLoading: false,
  error: null,
  success: false
}

export const authGeneralSlice = createSlice({
  name: 'authGeneral',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fastLogin.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fastLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.data.user;
        localStorage.clear();
        localStorage.setItem("access_token", action.payload.token.access_token)
        localStorage.setItem("refresh_token", action.payload.token.refresh_token)
        localStorage.setItem("authUser", JSON.stringify(action.payload.data.user))
        localStorage.setItem("customerId", JSON.stringify(action.payload.data.customer.id))
        window.location.href = '/dashboard/general';
      })
      .addCase(fastLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
  },
})

// Action creators are generated for each case reducer function
export const { } = authGeneralSlice.actions

export default authGeneralSlice.reducer