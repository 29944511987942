import React, { useContext, useEffect, useState } from 'react';
import { AppSettings } from '../../config/app-settings';
import { useDispatch, useSelector } from 'react-redux';
import { getUserId } from '../../helpers/auth';
import { getCustomerInfo } from '../../redux/apis/analyticsApiGeneral';
import AnalyticsBoxSocial from '../../components/Dashboard/social/AnalyticsBoxSocial';
import PerServiceStatsSocial from '../../components/Dashboard/social/PerServiceStatsSocial';
import TopCampaignsSocial from '../../components/Dashboard/social/TopCampaignsSocial';
import MonthlyDetectedRemovedSocial from '../../components/Dashboard/social/MonthlyDetectedRemovedSocial';
import TopRemovalsSocial from '../../components/Dashboard/social/TopRemovalsSocial';

const SocialDashboard = React.memo(() => {
    const context = useContext(AppSettings);
    
    


    useEffect(() => {
        context.setAppHeaderNone(false);
        context.setAppSidebarNone(false);
        context.setAppContentClass('');
        context.setAppThemePanal(true);

        return () => {
            context.setAppHeaderNone(true);
            context.setAppSidebarNone(true);
            context.setAppContentClass('p-0');
            context.setAppThemePanal(false);
        };
    }, [context]); // Add context as a dependency

    return (
        <div>
            <div className="row">
                <AnalyticsBoxSocial />
                <PerServiceStatsSocial />
                <TopCampaignsSocial />
                <div className='flex justify-between wrapContainer gap-4' style={{flexWrap:"wrap"}}>
                    <MonthlyDetectedRemovedSocial/>
                    <TopRemovalsSocial />
                </div>
            </div>
        </div>
    );
});

export default SocialDashboard;
